/**
 * @file
 * Styles for menus
*/

@import "variables";


#block-menu-block-2 {
  
  margin-top: 7px;
  
  @media only screen and (max-width: 767px) {
      margin-top: 0;
  }
  
  #mobile-search-link {
    
    display: none;
    font-family: 'fontello';
    content: 's';
    color: $tertiary-color;
    width: 40px;
    text-align: center;
    margin-left: 40px;
    margin-top: 10px;
    cursor: pointer;
    
    &:hover {
      text-decoration: none;
    }
    
    @media only screen and (max-width: 767px) {
      display: inline-block;
    }
  }
  
  .inner {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 4px;
  }
}